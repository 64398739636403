import { Add, Delete } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { fetchConfigData, saveConfigData } from '../../service/index';
import useStore from '../../state/index';
import Promotions from './Promotions';

const SiteDetails = () => {
    const [data, setData] = useState([]);
    const [selectedSiteIndex, setSelectedSiteIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const { apiAccessToken, setInfoMsg, setErrorMsg, setSuccessMsg } = useStore();
    const [promotionTypes, setPromotionTypes] = useState([]);
    const [promotions, setPromotions] = useState([]);

    const handlePromotionsChange = (updatedPromotions) => {
        setPromotions(updatedPromotions);
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true); // Start loading state
                const response = await fetchConfigData(apiAccessToken);

                if (response?.data?.length > 0) {
                    const formattedData = response.data.map((site) => ({
                        ...site,
                        shifts:
                            site.shifts?.map((shift) => ({
                                ...shift,
                                startTime: shift.startHour !== null ? dayjs().hour(shift.startHour).minute(0) : null,
                                endTime: shift.endHour !== null ? dayjs().hour(shift.endHour).minute(0) : null,
                            })) || [],
                        validUntil: site.validUntil ? dayjs(site.validUntil) : null,
                        reCheckinAllowedAfterHrs: site.reCheckinAllowedAfterHrs ?? 0,
                        promotions: site.promotions || [], // Ensure promotions field exists
                    }));

                    // Extract unique promotion types from the promotions array
                    const allPromotions = response.data.flatMap((site) => site.promotions || []);
                    const uniquePromotionTypes = [...new Set(allPromotions.map((promotion) => promotion.type))];
                    setData(formattedData);
                    setPromotions(allPromotions);
                    setPromotionTypes(uniquePromotionTypes);
                    setInfoMsg(response.message || 'Data loaded successfully.');
                } else {
                    setErrorMsg('No site data found.');
                }
            } catch (error) {
                console.error('Error fetching site details:', error);
                setErrorMsg('Error fetching site details. Please try again later.');
            } finally {
                setLoading(false); // End loading state
            }
        };

        loadData();
    }, [apiAccessToken]);

    const handleSiteChange = (event) => {
        setSelectedSiteIndex(Number(event.target.value));
    };

    const updateKey = (field, value) => {
        const updatedData = [...data];
        const site = { ...updatedData[selectedSiteIndex] };
        site[field] = value;
        updatedData[selectedSiteIndex] = site;
        setData(updatedData);
    };

    const handleDenominationChange = (index, value) => {
        const updatedData = [...data];
        const denominations = [...(updatedData[selectedSiteIndex].denominations || [])];
        denominations[index] = Number(value);
        updatedData[selectedSiteIndex].denominations = denominations;
        setData(updatedData);
    };

    const handleAddDenomination = () => {
        const updatedData = [...data];
        const denominations = [...(updatedData[selectedSiteIndex].denominations || [])];
        denominations.push(0);
        updatedData[selectedSiteIndex].denominations = denominations;
        setData(updatedData);
    };

    const handleRemoveDenomination = (index) => {
        const updatedData = [...data];
        const denominations = [...(updatedData[selectedSiteIndex].denominations || [])];
        updatedData[selectedSiteIndex].denominations = denominations.filter((_, i) => i !== index);
        setData(updatedData);
    };

    const handleShiftChange = (index, field, value) => {
        const updatedData = [...data];
        const shifts = [...(updatedData[selectedSiteIndex].shifts || [])];
        shifts[index] = { ...shifts[index], [field]: value };
        updatedData[selectedSiteIndex].shifts = shifts;
        setData(updatedData);
    };

    const handleAddShift = () => {
        const updatedData = [...data];
        const shifts = [...(updatedData[selectedSiteIndex].shifts || [])];
        shifts.push({
            startTime: null,
            endTime: null,
            order: shifts.length + 1,
        });
        updatedData[selectedSiteIndex].shifts = shifts;
        setData(updatedData);
    };

    const handleRemoveShift = (index) => {
        const updatedData = [...data];
        const shifts = [...(updatedData[selectedSiteIndex].shifts || [])];
        updatedData[selectedSiteIndex].shifts = shifts.filter((_, i) => i !== index);
        setData(updatedData);
    };

    const validateShifts = () => {
        const shifts = data[selectedSiteIndex]?.shifts || [];
        for (let i = 0; i < shifts.length - 1; i++) {
            const currentShiftEnd = shifts[i].endTime;
            const nextShiftStart = shifts[i + 1].startTime;

            if (currentShiftEnd && nextShiftStart && currentShiftEnd.isValid() && nextShiftStart.isValid() && currentShiftEnd.isAfter(nextShiftStart)) {
                setErrorMsg(`Shift ${shifts[i].order} (ends at ${currentShiftEnd.format('h A')}) overlaps with Shift ${shifts[i + 1].order} (starts at ${nextShiftStart.format('h A')}).`);
                return false;
            }
        }
        return true;
    };

    const handleSave = async () => {
        if (!validateShifts()) return;

        setSaving(true);
        try {
            const preparedData = { ...data[selectedSiteIndex] };

            // Convert shift times back to 24-hour format for the backend
            preparedData.shifts = preparedData.shifts.map((shift) => ({
                order: shift.order,
                startHour: shift.startTime ? parseInt(shift.startTime.format('HH'), 10) : null,
                endHour: shift.endTime ? parseInt(shift.endTime.format('HH'), 10) : null,
            }));

            // Convert `validUntil` to ISO string
            preparedData.validUntil = preparedData.validUntil ? preparedData.validUntil.toISOString() : null;
            preparedData.promotions = promotions;
            let response = await saveConfigData(apiAccessToken, preparedData);
            if (response.status == 200) {
                setSuccessMsg('Config saved successfully.');
            } else {
                setErrorMsg('Error saving site details.');
            }
        } catch (error) {
            console.error('Error saving site details:', error);
            setErrorMsg('Error saving site details.');
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (data.length === 0) {
        return <Typography color="error">No site data available.</Typography>;
    }

    const selectedSite = data[selectedSiteIndex];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
                <Typography variant="h6">Edit Site Details</Typography>

                {/* Site Selector */}
                <TextField select label="Select Machine" value={selectedSiteIndex} onChange={handleSiteChange} margin="normal">
                    {data.map((site, index) => (
                        <MenuItem key={site.siteId} value={index}>
                            {site.key}
                        </MenuItem>
                    ))}
                </TextField>

                {/* Valid Until Field */}
                <Typography variant="subtitle1" mt={2}>
                    Valid Until
                </Typography>
                <DatePicker
                    disabled
                    label="Valid Until"
                    value={selectedSite.validUntil}
                    onChange={(newValue) => updateKey('validUntil', newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                />

                {/* Re-Checkin Allowed After Hours Dropdown */}
                <Typography variant="subtitle1" mt={2}>
                    Re-Checkin Allowed After Hours
                </Typography>
                <TextField
                    select
                    label="Re-Checkin Allowed After Hours"
                    value={selectedSite.reCheckinAllowedAfterHrs !== undefined ? selectedSite.reCheckinAllowedAfterHrs : ''}
                    onChange={(event) => updateKey('reCheckinAllowedAfterHrs', Number(event.target.value))}
                    margin="normal"
                >
                    {[...Array(25).keys()].map((hour) => (
                        <MenuItem key={hour} value={hour}>
                            {hour} hrs
                        </MenuItem>
                    ))}
                </TextField>

                {/* Denominations Section */}
                <Typography variant="subtitle1" mt={4} mb={3}>
                    Denominations
                </Typography>
                <Box>
                    {selectedSite.denominations && selectedSite.denominations.length > 0 ? (
                        selectedSite.denominations.map((denomination, index) => (
                            <Box key={index} display="flex" alignItems="center" gap={1} mb={3}>
                                <TextField
                                    label={`Denomination ${index + 1}`}
                                    type="number"
                                    value={denomination ? Number(denomination) : ''}
                                    onChange={(e) => handleDenominationChange(index, e.target.value)}
                                    size="small"
                                />
                                <IconButton onClick={() => handleRemoveDenomination(index)} color="error">
                                    <Delete />
                                </IconButton>
                            </Box>
                        ))
                    ) : (
                        <Typography>No denominations available.</Typography>
                    )}
                    <Button startIcon={<Add />} onClick={handleAddDenomination}>
                        Add Denomination
                    </Button>
                </Box>

                {/* Shifts Section */}
                <Typography variant="subtitle1" mt={4} mb={3}>
                    Shifts
                </Typography>
                {selectedSite.shifts && selectedSite.shifts.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Shift</TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>End Time</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedSite.shifts.map((shift, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        <TimePicker
                                            label="Start Time"
                                            views={['hours']}
                                            value={shift.startTime}
                                            onChange={(newValue) => handleShiftChange(index, 'startTime', newValue)}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TimePicker
                                            label="End Time"
                                            views={['hours']}
                                            value={shift.endTime}
                                            onChange={(newValue) => handleShiftChange(index, 'endTime', newValue)}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleRemoveShift(index)} color="error">
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Typography>No shifts available.</Typography>
                )}
                <Button startIcon={<Add />} onClick={handleAddShift} sx={{ mt: 1 }}>
                    Add Shift
                </Button>

                <Promotions promotionTypes={promotionTypes} initialPromotions={promotions} onPromotionsChange={handlePromotionsChange} />

                {/* Save Button */}
                <Box mt={4}>
                    <Button variant="contained" onClick={handleSave} disabled={saving} sx={{ mr: 2 }}>
                        {saving ? 'Saving...' : 'Save'}
                    </Button>
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default SiteDetails;
