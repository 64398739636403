import { formatInTimeZone } from 'date-fns-tz';

export const formatDateWithTimeZone = (dateString, timeZone) => {
    if (!dateString || !timeZone) return ''; // Handle missing inputs gracefully

    try {
        // Convert the input date string to a Date object
        const date = new Date(dateString);

        // Format the date in the specified timezone with the abbreviation
        const formattedDate = formatInTimeZone(date, timeZone, 'yyyy-MM-dd hh:mm:ss a zzz');

        return formattedDate;
    } catch (error) {
        console.error('Invalid input provided:', { dateString, timeZone, error });
        return ''; // Return an empty string if an error occurs
    }
};
