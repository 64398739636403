// src/components/Footer/Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box component="footer" sx={{ py: 2, px: 3, mt: 'auto', bgcolor: 'primary.main', color: 'white' }}>
            <Typography variant="body2" align="center">
                © 2024 Game Fire. All Rights Reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
