// src/store/useStore.js
import { create } from 'zustand';
import { jwtDecode } from 'jwt-decode';

function extractUserRole(token) {
    try {
        const decodedToken = jwtDecode(token);
        const userRole = decodedToken['custom:role'];
        return userRole;
    } catch (error) {
        console.error('Error decoding token:', error);
        return null; // Return null if decoding fails
    }
}

function extractUserTz(token) {
    try {
        const decodedToken = jwtDecode(token);
        const userTz = decodedToken['custom:tz'];
        return userTz;
    } catch (error) {
        console.error('Error decoding token:', error);
        return null; // Return null if decoding fails
    }
}

const useStore = create((set) => ({
    userRole: '',
    userTz: '',
    apiAccessToken: '',
    setApiAccessToken: (token) => set({ apiAccessToken: token, userRole: extractUserRole(token), userTz: extractUserTz(token) }),
    loggedInUserName: '',
    setLoggedInUserName: (name) => set({ loggedInUserName: name.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) }),
    showComponent: 'DataTable',
    setShowComponent: (comp) => set({ showComponent: comp }),
    globalMessage: '',
    messageLevel: 'success',
    msgVisible: false,
    setInfoMsg: (msg) => set({ globalMessage: msg, messageLevel: 'info', msgVisible: true }),
    setSuccessMsg: (msg) => set({ globalMessage: msg, messageLevel: 'success', msgVisible: true }),
    setErrorMsg: (msg) => set({ globalMessage: msg, messageLevel: 'error', msgVisible: true }),
    setWarningMsg: (msg) => set({ globalMessage: msg, messageLevel: 'warning', msgVisible: true }),
}));

export default useStore;
