import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchReportingData } from '../../service/index';
import useStore from '../../state/index';
import ImagePreview from '../ImagePreview/ImagePreview';
import Search from '../Search/Search';
import SummaryTable from '../SummaryTable/SummaryTable';
import UserDetails from '../UserDetails/UserDetails';
import './DataTable.scss';
import { formatDateWithTimeZone } from '../../utils';

const DataTable = () => {
    const [apiResponse, setApiResponse] = useState({ data: [], message: 'No data available, Click Search to load data' });
    const [sortBy, setSortBy] = useState('currentCheckInDt');
    const [sortDirection, setSortDirection] = useState('desc');
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [action, setAction] = useState('Check_In'); // Can be 'Check_In' or 'Registration'
    const [fromDate, setFromDate] = useState(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState([]);
    const [isImagePreviewOpen, setImagePreviewOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const { apiAccessToken, setInfoMsg, userRole, userTz } = useStore();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects mobile screens

    const loadDataFromServer = async (action, fromDateOrName) => {
        try {
            const response = await fetchReportingData(apiAccessToken, action, fromDateOrName, userTz);
            setApiResponse(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        loadDataFromServer(action, new Date());
    }, [apiAccessToken]);

    const filterData = () => {
        if (apiResponse) {
            setFilteredData(apiResponse.data.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()) || String(item.phone).includes(searchQuery)));
        }
    };

    useEffect(() => {
        filterData();
    }, [searchQuery, apiResponse]);

    const handleSort = (column) => {
        const isAsc = sortBy === column && sortDirection === 'asc';
        setSortBy(column);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };

    const sortedData = filteredData.sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];

        if (['createTs', 'currentCheckInDt', 'lastCheckInDt'].includes(sortBy)) {
            return sortDirection === 'asc' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        }

        return 0;
    });

    const displayedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleDialogOpen = async (row) => {
        try {
            const response = await fetchReportingData(apiAccessToken, 'UserDetails', row.name, '', row.phone);
            if (response && response.data) {
                setSelectedUserData(response.data.sort((a, b) => new Date(b.createTs) - new Date(a.createTs)));
            } else {
                console.error('No data found in API response');
                setSelectedUserData([]);
            }
            setIsDialogOpen(true);
        } catch (error) {
            console.error('Error fetching user details:', error);
            setSelectedUserData([]);
        }
    };

    return (
        <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
            <SummaryTable data={filteredData} />

            <Search
                fromDate={fromDate}
                setFromDate={setFromDate}
                action={action}
                setAction={setAction}
                handleSearchBtnClick={() => loadDataFromServer(action, fromDate)}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
            />

            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <strong>Photo</strong>
                        </TableCell>
                        <TableCell>
                            <strong>Name</strong>
                        </TableCell>
                        {!isMobile && (
                            <TableCell>
                                <strong>Phone</strong>
                            </TableCell>
                        )}
                        {action === 'Registration' && (
                            <>
                                <TableCell>
                                    <strong>Registered On</strong>
                                </TableCell>
                            </>
                        )}
                        {action !== 'Registration' && (
                            <>
                                <TableCell>
                                    <strong>Current Check In</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Denomination</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Last Check In</strong>
                                </TableCell>
                            </>
                        )}
                        <TableCell>
                            <strong>Details</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayedData.length > 0 ? (
                        displayedData.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    {item.imgBase64 ? (
                                        <img
                                            src={`data:image/jpeg;base64,${item.imgBase64}`}
                                            alt="thumbnail"
                                            style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                                            onClick={() => {
                                                setSelectedImage(item.imgBase64);
                                                setImagePreviewOpen(true);
                                            }}
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                borderRadius: '50%',
                                                backgroundColor: '#e0e0e0',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                fontSize: '0.8rem',
                                                color: '#757575',
                                            }}
                                        >
                                            No Photo
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell>{item.name || 'N/A'}</TableCell>
                                {!isMobile && <TableCell>{item.phone || 'N/A'}</TableCell>}
                                {action === 'Registration' && <TableCell>{formatDateWithTimeZone(item.createTs, userTz)}</TableCell>}
                                {action !== 'Registration' && (
                                    <>
                                        <TableCell>{formatDateWithTimeZone(item.currentCheckInDt, userTz)}</TableCell>
                                        <TableCell>${item.denomination || '0'}</TableCell>
                                        <TableCell>{formatDateWithTimeZone(item.lastCheckInDt, userTz)}</TableCell>
                                    </>
                                )}
                                <TableCell>
                                    <Button variant="contained" onClick={() => handleDialogOpen(item)} disabled={userRole === 'Employee'}>
                                        View
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                                <Typography>Please adjust criteria & Click on Search</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
            />

            <ImagePreview isOpen={isImagePreviewOpen} image={selectedImage} onClose={() => setImagePreviewOpen(false)} />

            <UserDetails isOpen={isDialogOpen} data={selectedUserData} onClose={() => setIsDialogOpen(false)} timeZone={userTz} />
        </TableContainer>
    );
};

export default DataTable;
