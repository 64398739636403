import { Box, Grid, Paper, Typography } from '@mui/material';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import './SummaryTable.scss';

// Register Chart.js components and plugins
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, ChartDataLabels);

const SummaryTable = ({ data }) => {
    if (!data || data.length === 0) return null;

    const actionText = data[0]?.action || '';

    if (actionText === 'Registration') {
        const totalRecords = data.length;

        return (
            <Paper elevation={3} className="table-controls" sx={{ padding: 2 }}>
                <Box
                    sx={{
                        width: 150,
                        height: 150,
                        borderRadius: '50%',
                        backgroundColor: 'rgba(54, 162, 235, 0.1)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                        margin: '0 auto',
                    }}
                >
                    <Typography variant="h6" align="center">
                        <strong>Registrations</strong>
                    </Typography>
                    <Typography variant="h4" align="center">
                        <strong>{totalRecords}</strong>
                    </Typography>
                </Box>
            </Paper>
        );
    }

    const denominationData = data.reduce((acc, item) => {
        const denomination = Number(item.denomination) || 0; // Ensure denomination is a number
        if (!acc[denomination]) {
            acc[denomination] = { total: 0, count: 0 };
        }
        acc[denomination].total += denomination;
        acc[denomination].count += 1;
        return acc;
    }, {});

    const labels = Object.keys(denominationData).map((denomination) => `$${denomination}`);
    const counts = Object.values(denominationData).map((details) => details.count || 0); // Default to 0
    const totals = Object.values(denominationData).map((details) => details.total || 0); // Default to 0

    const totalCounts = Object.values(denominationData).reduce((sum, details) => {
        if (details.total > 0) {
            return sum + (details.count || 0); // Default to 0
        }
        return sum;
    }, 0);

    const totalAmount = Object.values(denominationData).reduce((sum, details) => sum + (details.total || 0), 0); // Default to 0

    const barChartData = {
        labels,
        datasets: [
            {
                label: 'Count',
                data: counts,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderWidth: 1,
            },
            {
                label: 'Total ($)',
                data: totals,
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                borderWidth: 1,
            },
        ],
    };

    const pieChartData = {
        labels,
        datasets: [
            {
                label: 'Total Value',
                data: totals,
                backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)', 'rgba(75, 192, 192, 0.6)', 'rgba(153, 102, 255, 0.6)', 'rgba(255, 159, 64, 0.6)'],
                borderWidth: 1,
            },
        ],
    };

    const barChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                enabled: true,
            },
            datalabels: {
                display: true,
                align: 'top',
                anchor: 'center',
                formatter: (value) => value,
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Denomination',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Value',
                },
                ticks: {
                    stepSize: 5,
                },
            },
        },
    };

    const pieChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                enabled: true,
            },
            datalabels: {
                formatter: (value, context) => {
                    const index = context.dataIndex;
                    const totalRecordCount = counts[index];
                    return `$${value}\n  [${totalRecordCount}]`;
                },
                color: '#fff',
                font: {
                    weight: 'bold',
                },
                align: 'center',
            },
        },
    };

    return (
        <Paper elevation={3} className="table-controls" sx={{ padding: 2 }}>
            <Grid
                container
                spacing={2}
                className="data-table"
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center', // Center the items
                }}
            >
                {/* Bar Chart */}
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className="data-table__cell"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexBasis: '33%', // Shrink to fit
                        minWidth: '300px',
                    }}
                >
                    <Box sx={{ width: '100%', maxWidth: '350px', height: '300px' }}>
                        <Bar data={barChartData} options={barChartOptions} />
                    </Box>
                </Grid>

                {/* Pie Chart */}
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className="data-table__cell"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexBasis: '33%', // Shrink to fit
                        minWidth: '300px',
                    }}
                >
                    <Box sx={{ width: '100%', maxWidth: '350px', height: '300px' }}>
                        <Pie data={pieChartData} options={pieChartOptions} />
                    </Box>
                </Grid>

                {/* Totals Circle */}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    className="data-table__cell"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexBasis: '33%', // Shrink to fit
                        minWidth: '300px',
                    }}
                >
                    <Box
                        sx={{
                            width: '150px',
                            height: '150px',
                            borderRadius: '50%',
                            backgroundColor: 'rgba(54, 162, 235, 0.1)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        <Typography variant="h6" align="center">
                            <strong>Totals</strong>
                        </Typography>
                        <Typography variant="body1" align="center">
                            Check-Ins: <strong>{totalCounts}</strong>
                        </Typography>
                        <Typography variant="body1" align="center">
                            Total: <strong>${totalAmount}</strong>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default SummaryTable;
