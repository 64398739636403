import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Grid, useMediaQuery, CircularProgress } from '@mui/material';
import { Bar, Line } from 'react-chartjs-2';
import './MonthlySummary.scss';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend, PointElement, LineElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fetchReportingData } from '../../service/index';
import useStore from '../../state/index';

ChartJS.register(BarElement, LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels);

const MonthlySummary = () => {
    // Get the current month in YYYY-MM format
    const getCurrentMonth = () => {
        const now = new Date();
        return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
    };

    const [month, setMonth] = useState(getCurrentMonth()); // Default to current month
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { apiAccessToken, setInfoMsg } = useStore();

    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        fetchMonthlySummary(); // Automatically fetch data for the current month on load
    }, []);

    const fetchMonthlySummary = async () => {
        setLoading(true);
        try {
            const response = await fetchReportingData(apiAccessToken, 'MonthlySummary', month);

            const data = response?.data;

            const labels = Object.keys(data); // Dates
            const registrations = labels.map((key) => data[key]?.registrations?.count || 0);

            // Dynamically extract all denominations from the first day's checkIns
            const denominations = Array.from(new Set(labels.flatMap((key) => Object.keys(data[key]?.checkIns || {}))));

            // Map check-ins data for each denomination
            const checkInsData = denominations.map((denom) => labels.map((key) => data[key]?.checkIns?.[denom]?.count || 0));

            // Construct datasets for charts
            const datasets = [
                {
                    label: 'Registrations',
                    data: registrations,
                    borderWidth: 2, // Make the line bold
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    fill: false,
                },
                ...denominations.map((denom, index) => ({
                    label: `Check-Ins (Denomination ${denom})`,
                    data: checkInsData[index],
                    borderWidth: 2, // Bold line for each dataset
                    borderColor: `rgba(${(index * 100) % 255}, ${(index * 150) % 255}, ${(index * 200) % 255}, 1)`,
                    backgroundColor: `rgba(${(index * 100) % 255}, ${(index * 150) % 255}, ${(index * 200) % 255}, 0.6)`,
                    fill: false,
                })),
            ];

            setChartData({
                labels,
                datasets,
            });
        } catch (error) {
            console.error('Error fetching monthly summary:', error);
        } finally {
            setLoading(false);
        }
    };

    const chartOptions = (isMobile) => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: isMobile ? 12 : 16,
                    },
                },
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (context) {
                        return `${context.dataset.label}: ${context.raw}`;
                    },
                },
            },
            datalabels: {
                display: !isMobile, // Hide numbers in mobile view
                color: 'black',
                font: {
                    size: 12,
                },
                anchor: 'end',
                align: 'top',
            },
        },
        layout: {
            padding: {
                top: 20,
                left: 10,
                right: 10,
                bottom: 10,
            },
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: isMobile ? 8 : 14, // Reduce font size for mobile
                    },
                    maxRotation: 45, // Tilt X-axis labels to 45 degrees
                    minRotation: 45,
                    autoSkip: false, // Ensure all dates are displayed
                },
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    font: {
                        size: isMobile ? 10 : 14, // Adjust Y-axis font size
                    },
                    padding: 5,
                    stepSize: 10, // Adjust step size for extra Y-axis room
                },
                suggestedMax: undefined,
                suggestedMin: 0,
                afterDataLimits: (axis) => {
                    axis.max += 10; // Add extra padding above the largest count
                },
                title: {
                    display: true,
                    text: 'Counts',
                    font: {
                        size: isMobile ? 12 : 14,
                    },
                },
            },
        },
    });

    return (
        <Container className="monthly-summary">
            <Typography variant="h4" gutterBottom>
                Monthly Summary
            </Typography>
            <Grid container spacing={2} alignItems="center" justifyContent="center" className="form">
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth label="Select Month" type="month" value={month} onChange={(e) => setMonth(e.target.value)} variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button fullWidth variant="contained" color="primary" onClick={fetchMonthlySummary} disabled={!month || loading}>
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Search'}
                    </Button>
                </Grid>
            </Grid>
            {loading && <CircularProgress />}
            {!loading && chartData && (
                <>
                    <div style={{ overflowX: 'auto', width: '100%' }}>
                        <Bar data={chartData} options={chartOptions(isMobile)} height={isMobile ? 300 : 400} />
                    </div>
                    <div style={{ overflowX: 'auto', width: '100%' }}>
                        <Line data={chartData} options={chartOptions(isMobile)} height={isMobile ? 300 : 400} />
                    </div>
                </>
            )}
        </Container>
    );
};

export default MonthlySummary;
