import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery, useTheme } from '@mui/material';
import './UserDetails.scss';
import { formatDateWithTimeZone } from '../../utils';

const UserDetails = ({ isOpen, data, onClose, timeZone }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects mobile screens

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>User Details</DialogTitle>
            <DialogContent dividers>
                {data.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <strong>Photo</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Name</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Phone</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Denomination</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Action</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Create Date</strong>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {item.imgBase64 ? (
                                            <img
                                                src={`data:image/jpeg;base64,${item.imgBase64}`}
                                                alt="thumbnail"
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    borderRadius: '8px',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    borderRadius: '50%',
                                                    backgroundColor: '#e0e0e0',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '0.8rem',
                                                    color: '#757575',
                                                }}
                                            >
                                                No Photo
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell>{item.name || 'N/A'}</TableCell>
                                    <TableCell>{item.phone || 'N/A'}</TableCell>
                                    <TableCell>${item.denomination || '0'}</TableCell>
                                    <TableCell>{item.action || 'N/A'}</TableCell>
                                    <TableCell>{formatDateWithTimeZone(item.createTs, timeZone)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <CircularProgress style={{ display: 'block', margin: 'auto' }} />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserDetails;
