// src/components/Header/Header.js
import { AppBar, Box, Button, IconButton, Toolbar, Typography, useMediaQuery, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import useStore from '../../state/index';
import NotificationRibbon from '../NotificationRibbon/NotificationRibbon';
import { useTheme } from '@mui/material/styles';

const Header = () => {
    const { loggedInUserName, globalMessage, messageLevel } = useStore();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            window.location.reload();
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    return (
        <>
            <AppBar position="fixed">
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="h6" component="div">
                        Game Fire
                    </Typography>
                    {isMobile ? (
                        <IconButton color="inherit" edge="end" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1" component="div" sx={{ mr: 2 }}>
                                Welcome, {loggedInUserName}
                            </Typography>
                            <Button color="inherit" onClick={handleSignOut}>
                                Sign Out
                            </Button>
                        </Box>
                    )}
                </Toolbar>
                <NotificationRibbon message={globalMessage} severity={messageLevel} />
            </AppBar>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 250 }}>
                    <List>
                        <ListItem>
                            <Typography variant="h6" component="div">
                                Game Fire
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={`Welcome, ${loggedInUserName}`} />
                        </ListItem>
                        <ListItem button onClick={handleSignOut}>
                            <ListItemText primary="Sign Out" />
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </>
    );
};

export default Header;
