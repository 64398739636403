// src/components/Layout/Layout.js
import React from 'react';
import { Box } from '@mui/material';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar'; // Make sure to create this component
import Footer from '../Footer/Footer'; // Make sure to create this component

const Layout = ({ children }) => {
    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            <Header />
            <Box component="main" sx={{ flexGrow: 1, display: 'flex' }}>
                <Sidebar />
                <Box component="div" sx={{ flexGrow: 1, padding: 2 }}>
                    {children}
                </Box>
            </Box>
            <Footer />
        </Box>
    );
};

export default Layout;
    