import { toDate } from 'date-fns-tz';

function convertToUTC(dateInput, timezone) {
    if (!dateInput) {
        return '';
    }
    let localDate;
    if (dateInput instanceof Date) {
        const year = dateInput.getFullYear();
        const month = String(dateInput.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = String(dateInput.getDate()).padStart(2, '0'); // Pad single-digit days with leading zero
        // Construct the formatted date
        const dateString = `${year}-${month}-${day}`;
        localDate = `${dateString}T01:00:00`;
    } else if (typeof dateInput === 'string') {
        // Use the provided string directly
        localDate = `${dateInput}T01:00:00`;
    } else {
        throw new Error('Invalid date input. Must be a Date object or YYYY-MM-DD string.');
    }
    // Convert the local time in the given timezone to a UTC Date object
    const utcDate = toDate(localDate, { timeZone: timezone });
    // Return the UTC Date as an ISO string
    return utcDate.toISOString();
}

export const fetchReportingData = async (apiAccessToken, action, fromDateOrName, tz, phone) => {
    if (apiAccessToken === '') {
        return;
    }
    let apiEndpoint = '';
    if (action === 'UserDetails') {
        apiEndpoint = `https://api.quikwish.com/report?action=${action}&name=${fromDateOrName}&phone=${phone}`;
    } else if (action === 'MonthlySummary') {
        apiEndpoint = `https://api.quikwish.com/report?action=${action}&forMonth=${fromDateOrName}`;
    } else if (action === 'AllRegistrations') {
        apiEndpoint = `https://api.quikwish.com/report?action=${action}`;
    } else {
        fromDateOrName = convertToUTC(fromDateOrName, tz);
        apiEndpoint = `https://api.quikwish.com/report?action=${action}&fromDate=${fromDateOrName}`;
    }
    try {
        const response = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${apiAccessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload();
            }
            return { data: [], message: 'Error fetching data. Please try again.', status: response.status };
        }

        const result = await response.json();
        return { data: result.data || [], message: result.message, status: response.status };
    } catch (error) {
        console.error('Error fetching data:', error);
        return { data: [], message: 'Error fetching data. Please try again.', status: 500 };
    }
};

export const fetchConfigData = async (apiAccessToken) => {
    if (apiAccessToken === '') {
        return { data: [], message: '' };
    }
    let apiEndpoint = 'https://api.quikwish.com/config';
    try {
        const response = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${apiAccessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload();
            }
            return { data: [], message: 'Error fetching data. Please try again.', status: response.status };
        }

        const result = await response.json();
        result.forEach((item) => {
            if (item.key) {
                const [part1, part2] = item.key.split('#'); // Split the key on '#'
                item.macAddress = part1;
                item.hostName = part2;
            }
        });
        return { data: result || [], message: 'Successfully Loaded Config Data', status: response.status };
    } catch (error) {
        console.error('Error fetching data:', error);
        return { data: [], message: 'Error fetching data. Please try again.', status: 500 };
    }
};

export const saveConfigData = async (apiAccessToken, configData) => {
    if (apiAccessToken === '') {
        return { data: [], message: '' };
    }
    delete configData.siteId;
    delete configData.validUntil;
    let apiEndpoint = 'https://api.quikwish.com/config';
    try {
        const response = await fetch(apiEndpoint, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${apiAccessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(configData),
        });

        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload();
            }
            return { data: [], message: 'Error Updating Config Data.', status: response.status };
        }

        return { data: [], message: 'Succesfully Saved Config Data', status: response.status };
    } catch (error) {
        console.error('Error fetching data:', error);
        return { data: [], message: 'Error Updating Config Data.', status: 500 };
    }
};
