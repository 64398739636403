import { Add, Delete } from '@mui/icons-material';
import { Box, Button, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useStore from '../../state/index';

dayjs.extend(utc);
dayjs.extend(timezone);

const Promotions = ({ promotionTypes, initialPromotions = [], onPromotionsChange }) => {
    const [promotions, setPromotions] = useState(initialPromotions);
    const { userTz } = useStore();

    const additionalPromotionTypes = ['TotalDenominationFromActivationDateToExtraDenominations', 'FrequencyAndTotalDenominationToExtraDenominations'];

    // Combine server-provided types with additional types, ensuring no duplicates
    const combinedPromotionTypes = Array.from(new Set([...promotionTypes, ...additionalPromotionTypes]));

    const handlePromotionFieldChange = (index, field, value) => {
        const updatedPromotions = [...promotions];
        updatedPromotions[index] = {
            ...updatedPromotions[index],
            [field]: field === 'pastNoOfDays' || field === 'totalDenominationValueAbove' ? Number(value) : value, // Ensure number fields
        };
        setPromotions(updatedPromotions);
        onPromotionsChange(updatedPromotions); // Notify parent component
    };

    const handleDenominationChange = (promotionIndex, denomIndex, value) => {
        const updatedPromotions = [...promotions];
        const denominations = [...(updatedPromotions[promotionIndex].denominations || [])];
        denominations[denomIndex] = Number(value); // Ensure number for denominations
        updatedPromotions[promotionIndex].denominations = denominations;
        setPromotions(updatedPromotions);
        onPromotionsChange(updatedPromotions); // Notify parent component
    };

    const handleAddDenomination = (promotionIndex) => {
        const updatedPromotions = [...promotions];
        const denominations = [...(updatedPromotions[promotionIndex].denominations || [])];
        denominations.push(0); // Default value as 0
        updatedPromotions[promotionIndex].denominations = denominations;
        setPromotions(updatedPromotions);
        onPromotionsChange(updatedPromotions); // Notify parent component
    };

    const handleRemoveDenomination = (promotionIndex, denomIndex) => {
        const updatedPromotions = [...promotions];
        const denominations = [...(updatedPromotions[promotionIndex].denominations || [])];
        updatedPromotions[promotionIndex].denominations = denominations.filter((_, i) => i !== denomIndex);
        setPromotions(updatedPromotions);
        onPromotionsChange(updatedPromotions); // Notify parent component
    };

    const handleAddPromotion = () => {
        const newPromotion = {
            type: combinedPromotionTypes[0] || '', // Default to the first promotion type
            denominations: [],
            pastNoOfDays: 0, // Default value as 0
            totalDenominationValueAbove: 0, // Default value as 0
            activationDt: '', // Default as empty for Activation Date
        };
        const updatedPromotions = [...promotions, newPromotion];
        setPromotions(updatedPromotions);
        onPromotionsChange(updatedPromotions); // Notify parent component
    };

    const handleRemovePromotion = (index) => {
        const updatedPromotions = promotions.filter((_, i) => i !== index);
        setPromotions(updatedPromotions);
        onPromotionsChange(updatedPromotions); // Notify parent component
    };

    const handleDateChange = (index, value) => {
        const centralTimeDate = dayjs.tz(value, userTz).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
        const updatedPromotions = [...promotions];
        updatedPromotions[index].activationDt = centralTimeDate;
        setPromotions(updatedPromotions);
        onPromotionsChange(updatedPromotions); // Notify parent component
    };

    return (
        <Box>
            <Typography variant="subtitle1" mt={4} mb={3}>
                Promotions
            </Typography>
            {promotions.length > 0 ? (
                promotions.map((promotion, index) => (
                    <Box key={index} mb={3} border="1px solid #ccc" borderRadius={2} p={2}>
                        <Box display="flex" alignItems="center" gap={2}>
                            {/* Promotion Type Dropdown */}
                            <TextField select label="Promotion Type" value={promotion.type} onChange={(e) => handlePromotionFieldChange(index, 'type', e.target.value)} size="small">
                                {combinedPromotionTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </TextField>

                            {/* Conditionally Rendered: Past No. of Days */}
                            {promotion.type !== 'TotalDenominationFromActivationDateToExtraDenominations' && (
                                <TextField
                                    label="Past No. of Days"
                                    type="number"
                                    value={promotion.pastNoOfDays || ''}
                                    onChange={(e) => handlePromotionFieldChange(index, 'pastNoOfDays', e.target.value)}
                                    size="small"
                                />
                            )}

                            {/* Total Denomination Value Above */}
                            <TextField
                                label="Total Denomination Value Above"
                                type="number"
                                value={promotion.totalDenominationValueAbove || ''}
                                onChange={(e) => handlePromotionFieldChange(index, 'totalDenominationValueAbove', e.target.value)}
                                size="small"
                            />

                            {/* Activation Date */}
                            {promotion.type === 'TotalDenominationFromActivationDateToExtraDenominations' && (
                                <TextField
                                    label="Activation Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    value={promotion.activationDt ? dayjs(promotion.activationDt).format('YYYY-MM-DD') : ''}
                                    onChange={(e) => handleDateChange(index, e.target.value)}
                                    size="small"
                                    // inputProps={{
                                    //     min: dayjs().format('YYYY-MM-DD'), // Ensure only future dates
                                    // }}
                                />
                            )}

                            {/* Remove Promotion Button */}
                            <IconButton onClick={() => handleRemovePromotion(index)} color="error">
                                <Delete />
                            </IconButton>
                        </Box>

                        {/* Denominations */}
                        <Typography variant="subtitle2" mt={2} mb={1}>
                            Denominations
                        </Typography>
                        {promotion.denominations && promotion.denominations.length > 0 ? (
                            promotion.denominations.map((denomination, denomIndex) => (
                                <Box key={denomIndex} display="flex" alignItems="center" gap={1} mb={2}>
                                    <TextField
                                        label={`Denomination ${denomIndex + 1}`}
                                        type="number"
                                        value={denomination || ''}
                                        onChange={(e) => handleDenominationChange(index, denomIndex, e.target.value)}
                                        size="small"
                                    />
                                    <IconButton onClick={() => handleRemoveDenomination(index, denomIndex)} color="error">
                                        <Delete />
                                    </IconButton>
                                </Box>
                            ))
                        ) : (
                            <Typography>No denominations available.</Typography>
                        )}
                        <Button startIcon={<Add />} onClick={() => handleAddDenomination(index)} sx={{ mt: 1 }}>
                            Add Denomination
                        </Button>
                    </Box>
                ))
            ) : (
                <Typography>No promotions available.</Typography>
            )}
            <Button startIcon={<Add />} onClick={handleAddPromotion} sx={{ mt: 2 }}>
                Add Promotion
            </Button>
        </Box>
    );
};

export default Promotions;
