// src/components/Home/Home.js
import { Container } from '@mui/material';
import { Auth } from 'aws-amplify';
import React, { useEffect, useRef } from 'react';
import useStore from '../../state/index';
import DataTable from '../DataTable/DataTable';
import './Home.scss'; // Ensure to import your SCSS
import SiteDetails from '../SiteDetails/SiteDetails';
import MonthlySummary from '../MonthlySummary/MonthlySummary';
import AllRegistrations from '../AllRegistrations/AllRegistrations';
import EmployeeCheckins from '../EmployeeCheckins/EmployeeCheckins';

function Home() {
    const { setApiAccessToken, setLoggedInUserName, setErrorMsg, showComponent } = useStore();
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            fetchJwtToken();
            initialized.current = true;
        }
    }, []);

    const fetchJwtToken = async () => {
        try {
            const session = await Auth.currentSession();
            const usernameFromToken = session.idToken.payload['cognito:username'] || session.idToken.payload['sub'];
            setLoggedInUserName(usernameFromToken);
            setApiAccessToken(session.idToken.jwtToken.trim());
        } catch (error) {
            console.error('Error fetching JWT token:', error);
            setErrorMsg('Error fetching JWT token');
        }
    };

    const renderComponent = () => {
        switch (showComponent) {
            case 'DataTable':
                return <DataTable />;
            case 'ConfigDetails':
                return <SiteDetails />;
            case 'MonthlySummary':
                return <MonthlySummary />;
            case 'AllRegistrations':
                return <AllRegistrations />;
            case 'EmployeeCheckins':
                return <EmployeeCheckins />;
            default:
                return <p>Please select in side bar to view.</p>;
        }
    };

    return (
        <Container className="home-container">
            <br />
            {renderComponent()}
            <br />
        </Container>
    );
}

export default Home;
