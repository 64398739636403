import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, IconButton, InputAdornment, MenuItem, Paper, Select, TextField, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import './Search.scss';

const Search = ({ fromDate, setFromDate, action, setAction, handleSearchBtnClick, searchQuery, setSearchQuery }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects screen size below 'sm'

    const handleSearchClick = () => {
        setSearchQuery(''); // Clear the filter field
        handleSearchBtnClick(); // Trigger the search button action
    };

    return (
        <Paper elevation={3} sx={{ padding: 2, marginY: 2 }}>
            <Grid
                container
                spacing={isMobile ? 1 : 2}
                alignItems="center"
                sx={{
                    width: '100%',
                    maxWidth: isMobile ? '100%' : '1200px', // Full width on mobile, restricted on desktop
                    margin: '0 auto', // Center align for desktop
                }}
            >
                {/* Date Field */}
                <Grid item xs={12} sm={1.6}>
                    <TextField
                        label="Date"
                        type="date"
                        variant="outlined"
                        onChange={(e) => setFromDate(e.target.value)}
                        value={fromDate}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        inputProps={{
                            max: new Date().toISOString().split('T')[0],
                        }}
                    />
                </Grid>

                {/* Filter By Select */}
                <Grid item xs={12} sm={1.6}>
                    <Select label="Filter By" value={action} onChange={(e) => setAction(e.target.value)} fullWidth>
                        <MenuItem value="Registration">Registrations</MenuItem>
                        <MenuItem value="Check_In">Check Ins</MenuItem>
                    </Select>
                </Grid>

                {/* Search Button or Icon */}
                <Grid item xs={isMobile ? 12 : 2}>
                    {isMobile ? (
                        <IconButton
                            color="primary"
                            onClick={handleSearchClick}
                            size="large"
                            sx={{
                                border: '1px solid rgba(0,0,0,0.2)',
                                borderRadius: '8px',
                                width: '100%', // Make the button take full width on mobile
                                minHeight: '3rem', // Consistent height
                            }}
                        >
                            <SearchIcon />
                        </IconButton>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSearchClick}
                            fullWidth
                            sx={{
                                minHeight: '3rem', // Consistent height
                            }}
                        >
                            Search
                        </Button>
                    )}
                </Grid>
                {/* Filter Text Field */}
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Filter by Name or Phone"
                        variant="outlined"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                        fullWidth
                        InputProps={{
                            endAdornment: searchQuery && (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setSearchQuery('')}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Search;
