import React from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './ImagePreview.scss';

const ImagePreview = ({ isOpen, image, onClose }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth="xl"
            PaperProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    boxShadow: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    padding: '0',
                },
            }}
            BackdropProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
            }}
        >
            <IconButton
                onClick={onClose}
                style={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                    overflow: 'hidden',
                }}
            >
                {image && (
                    <img
                        src={`data:image/jpeg;base64,${image}`}
                        alt="thumbnail-large"
                        style={{
                            width: '30vw',
                            height: 'auto',
                            maxWidth: '50vw',
                            maxHeight: '50vh',
                        }}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ImagePreview;
