import React, { useState, useEffect } from 'react';
import { Box, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './NotificationRibbon.scss';

const NotificationRibbon = ({ message, severity, duration = 5000 }) => {
    const [visible, setVisible] = useState(false);

    // Auto-close after the specified duration
    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, duration);
        if (message !== '') {
            setVisible(true);
        }
        // Clear the timeout if the component unmounts before the timer completes
        return () => clearTimeout(timer);
    }, [duration, message]);

    const handleClose = () => {
        setVisible(false);
    };

    return (
        visible && (
            <Box sx={{ width: '100%', position: 'fixed', top: 60, zIndex: 1200 }}>
                <Alert
                    severity={severity || 'info'}
                    variant="filled"
                    action={
                        <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ justifyContent: 'center' }}
                >
                    {message}
                </Alert>
            </Box>
        )
    );
};

export default NotificationRibbon;
